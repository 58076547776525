import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import Head from "../../components/Head"
import "../../styles/categories.css"
import sopasData from "../../utils/menu/sopasData"

const pollos = () => (
  <>
    <Head
      description="También tenemos deliciosas sopas como el típico Ajiaco"
      title="Menu: Sopas - Asadero RoqueFer"
      keywords="Sopas Ajiaco sopa menudencias"
    />
    <Layout>
      <main>
        <div className="products-container soups">
          <h3>Sopas</h3>
          {sopasData.map(current => {
            return (
              <div className="product">
                <Link to="/pedidos">
                  <picture>
                    <source
                      srcSet={current.imageDesktopSrc}
                      media="(min-width: 500px)"
                    />
                    <img
                      loading="lazy"
                      src={current.imageSrc}
                      alt={current.imageDescription}
                    />
                  </picture>
                </Link>
              </div>
            )
          })}
        </div>
      </main>
    </Layout>
  </>
)

export default pollos
