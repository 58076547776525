import ajiaco from "../../../static/assets/images/menu/sopas/sopaajiaco.jpg"
import ajiaco_desktop from "../../../static/assets/images/menu/sopas/desktop/AJIACO.jpg"
import ajiaco_pollo from "../../../static/assets/images/menu/sopas/sopaajiacoconpollo.jpg"
import ajiaco_pollo_desktop from "../../../static/assets/images/menu/sopas/desktop/AJIACO_CON_POLLO.jpg"
import menudencias from "../../../static/assets/images/menu/sopas/sopamenudencias.jpg"
import menudencias_desktop from "../../../static/assets/images/menu/sopas/desktop/MENUDENCIAS.jpg"

const sopasData = [
  {
    imageSrc: ajiaco,
    imageDesktopSrc: ajiaco_desktop,
    imageDescription: "Deliciosa sopa de Ajiaco",
  },
  {
    imageSrc: ajiaco_pollo,
    imageDesktopSrc: ajiaco_pollo_desktop,
    imageDescription: "Deliciosa sopa de Ajiaco con pollo desmechado",
  },
  {
    imageSrc: menudencias,
    imageDesktopSrc: menudencias_desktop,
    imageDescription: "Deliciosa sopa de menudencias",
  },
]

export default sopasData
